const boardFilterConfig = {
  perform: {
    getFunc: 'getBoard',
    listApiParamSet: {
      ip: 1,
      sp: 10,
    },
  },

  performDetail: {
    getFunc: 'getBoard',
    detailApiParamSet: {
      ip: 0,
      f: 1,
    },
    getKeyName: 'id',
  },
}

export default {
  methods: {
    returnBoardFilterConfig(type) {
      return boardFilterConfig[type];
    }
  },
  filterConfig: {}
}