let isDev = false;

let config = {
    dev: {
        apiDomain: "https://devapi.hoddak.com",
        searchElaUrl: '',
        bucketOption: {
        },
        elasticOption: {
            index: '',
            type: '',
        },
        imageDomain: "https://imagedev.hoddak.com/",
    },
    prod: {
        apiDomain: "https://api.hoddak.com",
        searchElaUrl: '',
        bucketOption: {
        },
        elasticOption: {
            index: '',
            type: '',
        },
        imageDomain: "https://image.hoddak.com/",
    },
}

function getConfig(key = 'apiDomain') {
    return isDev ? config.dev[key] : config.prod[key];
}

function setConfig(key, value) {
    if (isDev) {
        config.dev[key] = value;
    } else {
        config.prod[key] = value;
    }
}

let apiDomain = getConfig('apiDomain')
let searchElaUrl = getConfig('searchElaUrl')
let apiURL = `${getConfig('apiDomain')}/api/`;
let bucketOption = getConfig('bucketOption');
let imageDomain = getConfig('imageDomain');
let elasticOption = getConfig('elasticOption');

function setterApiDomain(domain) {
    setConfig('apiDomain', domain);
    apiDomain = getConfig('apiDomain');
    apiURL = `${getConfig('apiDomain')}/api/`;
    let devDomain = config.dev.apiDomain;
    let prodDomain = config.prod.apiDomain;
    if (devDomain == domain) {
        isDev = true;
    } else if (prodDomain == domain) {
        isDev = false;
    }
    bucketOption = getConfig('bucketOption');
    searchElaUrl = getConfig('searchElaUrl')
    imageDomain = getConfig('imageDomain');
    elasticOption = getConfig('elasticOption');
}


export {apiURL, apiDomain, imageDomain, setterApiDomain, bucketOption, searchElaUrl, elasticOption};
