import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import {request} from "@/api/request";

export default {
    mixins: [imageOption],
    methods: {

        returnUserProfile(char = 10, profile = [], imageOption = {w: 33, h: 33}) {
            let image = '';
            if (char === 0) {
                image = require("@/assets/image/default_profile.png");
            } else if (char === 1) {
                image = require("@/assets/image/mypage/pro1.png");
            } else if (char === 2) {
                image = require("@/assets/image/mypage/pro2.png");
            } else if (char === 3) {
                image = require("@/assets/image/mypage/pro3.png");
            } else if (char === 4) {
                image = require("@/assets/image/mypage/pro4.png");
            } else if (char === 5) {
                image = require('@/assets/image/mypage/pro.png');
            } else if (!util.isEmpty(profile)) {
                let w = util.isEmpty(imageOption.w) ? 100 : imageOption.w;
                let h = util.isEmpty(imageOption.h) ? 100 : imageOption.h;
                image = `${profile.org_url}?${this.setImageOptions(w, h)}`;
            }
            return image;
        },


    },
}
