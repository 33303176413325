import Vue from 'vue';
import {request, setAuthInHeader} from "@/api/request";
// import {req} from "vuelidate/src/validators/common";


Vue.prototype.$api = {
  $perform: {
    getPerform(params) {
      return request.get("perform", params).then(({data}) => data);
    },
  },
  $board: {
    getBoard(params) {
      return request.get("board/pcboard", params).then(({data}) => data);
    },
    getReview(params) {
      return request.get("review", params).then(({data}) => data);
    },
  },
  $story: {
    getStory(params) {
      return request.get("story", params).then(({data}) => data);
    },
  },
  $vote: {
    getVoteList(params) {
      return request.get("vote", params).then(({data}) => data);
    },
    getVote(params) {
      return request.get("vote/find", params).then(({data}) => data);
    },
    getUser(params) {
      return request.get("vote/user", params).then(({data}) => data);
    },
  },

  $handler: {
    authHeader: setAuthInHeader
  }
};

export default Vue.prototype.$api;
