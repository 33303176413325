const state = {
    filterSet: {
        filter:{},
        page: {
            page: 1,
            size: 0,
        },
        sort: {},
    },
};

const getters  = {
    getFilterSet: state => state.filterSet,
};

const mutations = {
    updateFilterSet(state, obj) {
        state.filterSet = obj.data;
    },
};

const actions = {
    setFilterSet({commit, rootState}, {data}) {
        commit('updateFilterSet', {data:data});
    }
};

export default { namespaced: true, state, getters, mutations, actions }
