<template>
  <component :is="component" v-if="component" :boardData="boardData"></component>
</template>

<script>
export default {
  name: "BoardDetailLink",
  mixins: [],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {
    detailLayout: '',
    boardData: '',
  },
  data() {
    return {
      component: null,
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {
    this.detailLoader();
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {
    loader() {
      if (!this.detailLayout) {
        return null
      }
      return () => import(`@/components/board/layout/boardList/detail/${this.detailLayout}`)
    },
  },
  methods: {
    detailLoader() {
      this.loader()
        .then(() => {
          this.component = () => this.loader()
        })
        .catch(() => {
          this.component = () => import('@/components/board/layout/boardList/detail/BoardDetailDefaultLayout')
        })
    }
  },
  watch: {},
};
</script>

<style scoped>

</style>