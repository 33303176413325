<template>
    <component :is="component" v-if="component"></component>
</template>

<script>
export default {
    name: "BoardFilterLink",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        filterLayout: '',
    },
    data() {
        return {
            component: null
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {

    },
    methods: {
    },
    watch: {},
};
</script>

<style scoped>

</style>