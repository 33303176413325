import 'babel-polyfill'

import Vue from 'vue'
import Vuex from 'vuex'
import perform from "@/store/perform/Perform";
import common from "@/store/modules/common";

Vue.use(Vuex);

const state = {
    PagePathStatus: true, //true(parent), false(child)
    PageParentBackPath: () => {
    },
    PageChildBackPath: () => {
    },
    partner: {},
    backStatus: {
        registerBack: true
    }
};

export default new Vuex.Store({
    state,
    modules: {
        perform,
        common,
    }
})
