import Api from '@/mixins/list/api';
import Content from "@/mixins/list/content";
import util from "@/mixins/util";
export default {
    data() {
        return {
            List: [],
            listClass : null,

            paginationType: '',
            hasPre: false,
            finishGetList: true,
            isGetFirst: true,

        }
    },
    methods: {
        initList(type, options) {
            switch (type) {
                case 'api':
                    this.listClass = new Api(options);
                    break;
                case 'content':
                    this.listClass = new Content(options);
                    break;
            }
        },
        getList(isFirst=false) {
            let filterSet = JSON.parse(JSON.stringify(this.getFilterSet()));
            if(isFirst) {
                let parsingDataPage = this.getPasingDataPage();
                filterSet.page.page = parsingDataPage;
                this.List = [];
            }else if(this.isInitPage === true) {
                /*this.filterSet.page.page = 1;
                this.setPage('page', 1);*/
                filterSet.page.page = 1;
            }
            if(this.listClass === null) {
                return false;
            }
            this.listClass.getList(this.filterSet).then(res => {
                let list = [];
                if (util.isEmpty(res.list)) {
                    list = [];
                } else {
                    list = res.list.slice();
                }
                if(this.paginationType === 'lazyload' && this.List.length > 0 && this.hasPre) {
                    this.List = list.concat(this.List.slice());
                }else if(this.paginationType === 'lazyload' && this.List.length > 0) {
                    this.List = this.List.concat(list.slice());
                }else{
                    this.List = list.slice();
                }
                if(res.hasOwnProperty('total')) {
                    this.setPage('total', res.total);
                }
                if(filterSet.page.page != this.filterSet.page.page && this.isInitPage === true) {
                    this.setPage('page', filterSet.page.page);
                }
                if(isFirst) {
                    this.setParsingDataPageFilterSet();
                }
                this.isInitPage = false;
            })
        },
    },
    watch: {
    }
}
