import {request, setAuthInHeader} from "@/api/request";
import Vue from 'vue'
import util from "@/mixins/util";

const state = {
    UserInfo: {},
};

const getters = {
    getUserInfo: state => state.UserInfo,
};

const mutations = {
    updateUserInfo(state, obj) {
        state.UserInfo = obj.value;
    },
    resetUserInfo(state) {
        state.UserInfo = {};
    },

};

const actions = {
    setPerform({commit, rootState}, {key}) {
        let params = {
            mt: 'self',
            fi:1,
        }
        request.get('member', params)
          .then((res) => {
              let result = res.data.Data.Result;
              if (result.Info.type > 0 && result.List.length > 0) {
                  commit('updateUserInfo', {'value': result.List[0]});
              } else {
                  commit('updateUserInfo', {'value': {}})
              }
          }, () => {
              console.log('error');
          })
    },
    changePerform({commit, rootState}, {data}) {
        commit('updatePerform', {'value': data});
    },
    initPerform({commit, rootState}, {data}) {
        commit('resetPerform');
    },
};

export default {namespaced: true, state, getters, mutations, actions}
