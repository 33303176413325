<template>
    <div class="hover-area-footer" v-if="showLayout">
        <div class="container-footer">
            <button class="btn-footer back-btn pink" @click="$router.push(`/`)">
                공연 변경
            </button>
            <div v-show="hasBack" class="btn-center-container">
                <button class="btn-footer pink" @click="$router.back()">목록</button>
            </div>
            <div class="btn-footer-right__container">
                <button class="btn-footer" :class="{ 'pink' : tab === 'perform'}"
                        @click="movePage(`/perform/${performKey}`)">
                    게시판
                </button>
                <button class="btn-footer" :class="{ 'pink' : tab === 'review'}" v-if="performData.p_is_review === 1"
                        @click="movePage(`/review/${performKey}`)">
                    후기
                </button>
                <button class="btn-footer" :class="{ 'pink' : tab === 'story'}" v-if="performData.p_is_story === 1"
                        @click="movePage(`/story/${performKey}`)">
                    사연
                </button>
                <button class="btn-footer" :class="{ 'pink' : tab === 'vote'}" v-if="performData.p_is_vote === 1"
                        @click="movePage(`/vote/${performKey}`)">
                    투표
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "PageFooter",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        hasBack: {
            type: Boolean,
            default: false,
        },
        performKey: 0,
    },
    data() {
        return {
            tab: '',
            performData: {},
            showLayout: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.setTab();
        this.getPerform();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setTab() {
            this.tab = this.$route.meta.title
        },

        movePage(url) {
            let currentUrl = this.$route.path
            if (url !== currentUrl) {
                return this.$router.push(url);
            }
        },
        getPerform() {
            this.showLayout = true;
            let params = {
                id: this.performKey
            }
            this.$api.$perform.getPerform(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type === 1) {
                    this.performData = res.List[0];
                }
            })
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
