<template>
    <component :is="component" v-if="component" :paginationData="paginationData"></component>
</template>

<script>
export default {
    name: "PaginationLink",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        paginationLayout: '',
        paginationData: {
            type: Object,
            default: {
                total: 0,
                page: 0,
                size: 0,
            }
        },
    },
    data() {
        return {
            component: null
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.paginationLoader();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        loader() {
            if (!this.paginationLayout) {
                return null
            }
            return () => import(`@/components/board/layout/boardList/pagination/${this.paginationLayout}`)
        },
    },
    methods: {
        paginationLoader() {
            this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/components/board/layout/boardList/pagination/PaginationDefaultLayout')
                })
        }
    },
    watch: {},
};
</script>

<style scoped>

</style>