<template>
  <div>
    <div class="container-home">
      <review-component :memberKey="memberKey"></review-component>
    </div>
    <page-footer :performKey="memberKey"></page-footer>
  </div>
</template>

<script>
import PageFooter from "@/components/PageFooter";
import ReviewComponent from "@/components/review/ReviewComponent";

export default {
  name: "Review",
  mixins: [],
  components: {ReviewComponent, PageFooter},
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
      memberKey: this.$route.params.idx,
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {},
  watch: {},
}
</script>

<style scoped>

</style>
