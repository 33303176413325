<template>
    <div class="wrapper-swiper">
        <swiper :options="swiperOption">
            <swiper-slide v-for="(data, index) in performData" :key="`perform${index}`">
                <div class="box-swiper-list" @click="movePage(data)">
                    <div class="img-list" :style="`background-image: url(${returnImage(data)})`"></div>
                    <div class="swiper-item-subject">{{ data.p_name }}</div>
                </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import {mapState} from "vuex";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";

export default {
    name: "PerformSwiperComponent",
    mixins: [alertMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            swiperOption: {
                slidesPerView: 4,
                slidesPerGroup: 4,
                spaceBetween: 30,
                centeredSlides: false,
                loopFillGroupWithBlank: false,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
            },
            performData: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {
        this.getPerform();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        getPerform() {
            let params = {
                di: 1, // 노출된 공연
            };
            EventBus.$emit('changeLoading', true);
            try {
                this.$api.$perform.getPerform(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type !== 1) {
                        this.errorAlert('데이터를 불러올 수 없습니다.');
                        return false;
                    } else {
                        this.performData = res.List;
                        EventBus.$emit('changeLoading', false);
                    }
                })
            } catch (e) {
                console.log(e);
                EventBus.$emit('changeLoading', false);
            }
        },

        setPerform(data) {
            let setPerform = this.UserInfo;
            setPerform.p_idx = data.p_idx;
            setPerform.p_is_review = data.p_is_review;
            setPerform.p_is_story = data.p_is_story;
            setPerform.p_is_vote = data.p_is_vote;

            this.$localStorage.set('perform', {data: setPerform});
        },

        movePage(data) {
            return this.$router.push(`/perform/${data.p_idx}`)
        },


        returnImage(data) {
            if (this.performData.length > 0 && !util.isEmpty(data.File.pcPerform)) {
                return data.File.pcPerform[0].org_url;
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
