<template>
    <div v-loading="!finishGetList">
        <board-filter-link :filterLayout="filterLayout" :filterData="filterSet.filter"></board-filter-link>
        <board-list-link ref="boardListLink" :listLayout="listLayout" :boardData="List" :paginationData="filterSet.page"></board-list-link>
        <pagination-link :paginationLayout="paginationLayout" :paginationData="filterSet.page"></pagination-link>
    </div>
</template>

<script>
import BoardListLink from "@/components/board/link/BoardListLink";
import PaginationLink from "@/components/board/link/PaginationLink";
import boardFilterUtil from "@/components/board/boardFilterUtil";
import boardTemplateUtil from "@/components/board/boardTemplateUtil";
import filterMixins from "@/mixins/filterMixins";
import listMixins from "@/mixins/listMixins";
import BoardEmptyLink from "@/components/board/link/BoardEmptyLink";
import BoardFilterLink from "@/components/board/link/BoardFilterLink";

const {filterConfig} = boardFilterUtil;
export default {
    name: "BoardListComponent",
    mixins: [boardFilterUtil, boardTemplateUtil, filterMixins, listMixins],
    components: {BoardFilterLink, BoardEmptyLink, PaginationLink, BoardListLink},
    inject: [],
    provide() {
        return {
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
        }
    },
    props: {
        boardType: {},
        defaultFilter: {
            default: () => {
            }
        },
    },
    data() {
        return {
            filterLayout: '',
            listLayout: '',
            emptyLayout: '',
            paginationLayout: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        this.setFilterConfig(filterConfig)
        this.setParserType('url', this.$route.fullPath);
    },
    mounted() {
        if (this.isGetFirst) {
            this.getList(true);
        } else {
            this.finishGetList = true;
        }
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let boardTemplateConfig = this.returnBoardTemplateConfig(this.boardType);
            this.filterLayout = boardTemplateConfig.filterLayout;
            this.listLayout = boardTemplateConfig.listLayout;
            this.emptyLayout = boardTemplateConfig.emptyLayout
            this.filterLayout = boardTemplateConfig.filterLayout;
            this.paginationLayout = boardTemplateConfig.paginationLayout;

            let boardFilterConfig = this.returnBoardFilterConfig(this.boardType);
            this.getFunc = boardFilterConfig.getFunc;
            this.listApiParamSet = boardFilterConfig.listApiParamSet;

            let defaultParams = Object.assign({}, this.listApiParamSet, this.defaultFilter);
            let listOptions = {
                url: this.$api.$board[this.getFunc],
                defaultParams: defaultParams,
            }
            this.initList('api', listOptions);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
