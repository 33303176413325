import {storeSet, authPageCheck} from "@/mixins/Auth.js";

function setViewResolver(component) {
    return require('../views/' + component).default;
}

const layout = {
    render(h) {
        return h('router-view')
    }
}

export default [
    {
        path: '/',
        component: setViewResolver('Home'),
        name: 'Home',
        meta: {name: 'home', title: 'home'},
    }, {
        path: '/perform/:idx',
        component: setViewResolver('perform/Perform'),
        name: 'Perform',
        beforeEnter: storeSet,
        meta: {name: 'perform', title: 'perform'},
    }, {
        path: '/image/:idx/:wr_id',
        component: setViewResolver('perform/PerformDetail'),
        name: 'PerformDetail',
        beforeEnter: storeSet,
        meta: {name: 'PerformDetail', title: 'PerformDetail'},
    },
    {
        path: '/review/:idx',
        component: setViewResolver('review/Review'),
        name: 'Review',
        beforeEnter: storeSet,
        meta: {name: 'review', title: 'review'},
    }, {
        path: '/story/:idx',
        component: setViewResolver('story/Story'),
        name: 'Story',
        beforeEnter: storeSet,
        meta: {name: 'story', title: 'story'},
    }, {
        path: '/vote/:idx',
        component: setViewResolver('vote/Vote'),
        name: 'Vote',
        beforeEnter: storeSet,
        meta: {name: 'vote', title: 'vote'},
    }, {
        path: '/privacy',
        component: setViewResolver('privacy/Privacy'),
        name: 'Privacy',
        beforeEnter: storeSet,
        meta: {name: 'privacy', title: 'privacy'},
    },

]
