<template>
  <div class="">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: "RouteView",
  components: {},
  data() {
    return {
      data: {}
    }
  },
  mounted() {
  },
  methods: {}
}
</script>
