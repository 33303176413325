const boardTemplateConfig = {
  perform: {
    filterLayout: '',
    listLayout: 'BoardListDefaultLayout',
    emptyLayout: '',
    paginationLayout: 'PaginationDefaultLayout',
  },

  performDetail: {
    detailLayout: 'BoardDetailDefaultLayout',
  },
}

export default {
  methods: {
    returnBoardTemplateConfig(type) {
      return boardTemplateConfig[type];
    }
  },
  filterConfig: {}
}