<template>
  <div>
    <board-detail-link :boardData="boardData" :detailLayout="detailLayout"></board-detail-link>
  </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import BoardDetailLink from "@/components/board/link/BoardDetailLink";
import boardTemplateUtil from "@/components/board/boardTemplateUtil";
import boardFilterUtil from "@/components/board/boardFilterUtil";

export default {
  name: "BoardDetailComponent",
  mixins: [alertMixins, boardTemplateUtil, boardFilterUtil, ],
  components: {BoardDetailLink,},
  inject: [],
  provide() {
    return {}
  },
  props: {
    boardType: '',
    boardKey: 0,
  },
  data() {
    return {
      boardData: [],
      detailLayout: '',

      getFunc: '',
      detailApiParamSet: {},
      getKeyName: '',
    }
  },
  beforeRouterEnter() {
  },
  created() {
    this.initConfig()
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    initConfig() {
      let boardTemplateConfig = this.returnBoardTemplateConfig(this.boardType);
      this.detailLayout = boardTemplateConfig.detailLayout;

      let boardFilterConfig = this.returnBoardFilterConfig(this.boardType);
      this.getFunc = boardFilterConfig.getFunc;
      this.detailApiParamSet = boardFilterConfig.detailApiParamSet;
      this.getKeyName = boardFilterConfig.getKeyName;

      this.getBoard();
    },
    getBoard() {
      let params = Object.assign({}, this.detailApiParamSet);
      params[this.getKeyName] = this.boardKey;
      EventBus.$emit('changeLoading', true);
      try {
        this.$api.$board[this.getFunc](params).then(res => res.Data.Result).then(res => {
          if (res.Info.type !== 1) {
            this.errorAlert('데이터를 불러올 수 없습니다.');
            this.$router.back();
            EventBus.$emit('changeLoading', false);
            return false;
          }
          this.boardData = res.List[0]
          EventBus.$emit('changeLoading', false);
        })
      } catch (e) {
        console.log(e);
        EventBus.$emit('changeLoading', false);
      }
    },

  },
  watch: {},
}
</script>

<style scoped>

</style>
