<template>
    <div class="vote-wrap">
        <div class="container-vote" v-if="voteData.length > 0">
            <div class="img-logo"></div>

            <div class="vote-box">
                <div class="wrapper-vote" v-for="(data, index) in voteData" :key="`vote${index}`">
                    <div class="img-vote" :style="returnImage(data)"></div>

                    <div class="wrapper-num">
                        <div class="back-white"></div>
                        <vote-user-component :voteData="voteData"
                                             :userKey="voteData[index].vc_idx"></vote-user-component>
                    </div>

                </div>
            </div>
        </div>
        <div v-else class="empty-wrap vote-empty-wrap">
            등록된 투표가 없습니다.
        </div>
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import util from '@/mixins/util';
import VoteUserComponent from "@/components/vote/VoteUserComponent";
import imageOption from "@/mixins/imageOption";

export default {
    name: "VoteComponent",
    mixins: [alertMixins, imageOption],
    components: {VoteUserComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {
        voteKey: 0,
    },
    data() {
        return {
            voteData: [],
            backImage: '',
            voteList: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.getList();
        EventBus.$on('setBackImage', this.returnBackImage);
    },
    beforeDestroy() {
        EventBus.$off('setBackImage');
    },
    destroyed() {
    },
    computed: {},
    methods: {
        getList() {
            let params = {};
            params.pid = this.voteKey;
            EventBus.$emit('changeLoading', true);

            try {
                this.$api.$vote.getVoteList(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type !== 1 || util.isEmpty(res.List)) {
                        // this.errorAlert( '매칭된 투표 이벤트가 없습니다.');
                        // this.$router.back();
                        // return false;
                    }
                    this.voteList = res.List;

                    this.voteList.find(item => {
                        if (item.v_display === 0) {
                            return
                        }
                        this.getVote(item.v_idx);
                    })


                    EventBus.$emit('changeLoading', false);
                })
            } catch (e) {
                console.log((e));
                EventBus.$emit('changeLoading', false);
            }
        },

        getVote(idx) {
            let params = {};
            params.v_idx = idx;
            EventBus.$emit('changeLoading', true);
            try {
                this.$api.$vote.getVote(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type !== 1) {
                        this.errorAlert('데이터를 불러올 수 없습니다.');
                        this.$router.back();
                        EventBus.$emit('changeLoading', false);
                        return false;
                    }

                    this.voteData = res.List.candidate;
                    this.backImage = res.List.pcVote;
                    EventBus.$emit('changeLoading', false);
                })
            } catch (e) {
                console.log(e);
                EventBus.$emit('changeLoading', false);
            }
        },

        returnBackImage() {
            if (!util.isEmpty(this.backImage)) {
                return this.backImage[0].org_url;
            }
        },

        returnImage(image) {
            if (image.hasOwnProperty('file') && !util.isEmpty(image.file.length > 0)) {
                return `background-image: url(${image.file[0].org_url}?${this.setImageOptions(122, 152, 'webp')})`;
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
