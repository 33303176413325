<template>
    <div class="container-story">
        <div v-if="storyStatus">
            <div class="img-logo" style="visibility:hidden"></div>
            <VTextMarquee :speed="showSpeed" class="wrapper-marquee" ref="marquee">
                <div class="wrapper-marquee-content">
                    <div v-for="(data, index) in storyData[currentIndex]" :key="`story${index}`"
                         class="wrapper-content">
                        <div class="header-content">
                            <div class="img-profile"
                                 :style="`background-image: url(${returnUserProfile(data.mb_profile,returnFile(data),{w:36,h:36})})`"></div>
                            {{ data.mb_nick }}
                        </div>
                        <div class="body-content">
                            {{ data.s_story }}
                        </div>
                    </div>
                </div>
            </VTextMarquee>
        </div>

        <div class="empty-wrap" v-else>
            등록된 사연이 없습니다.
        </div>

        <div class="text-bottom">
            <div class="img-left"></div>
            <div class="img-right"></div>
            우리 친구들의 사연을 전송해주세요
        </div>
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import {VTextMarquee} from 'vue-text-marquee';
import util from "@/mixins/util"
import Swal from "sweetalert2";

let storyInterval = null;
let showSetTimeout = null;
import memberMixins from "@/mixins/memberMixins";

export default {
    name: "StoryComponent",
    mixins: [alertMixins, memberMixins],
    components: {VTextMarquee},
    inject: [],
    provide() {
        return {}
    },
    props: {
        memberKey: 0,
    },
    data() {
        return {
            storyData: [],
            showSpeed: 150,
            nowKey: '',
            currentIndex: 0,
            allStoryData: [],
            firstShow: false,
            storyStatus: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.getStory();
        storyInterval = setInterval(function () {
            this.getStory();
        }.bind(this), 5000);
        this.checkShowData()
    },
    beforeDestroy() {
    },
    destroyed() {
        if (storyInterval) {
            clearInterval(storyInterval);
        }
        if (showSetTimeout) {
            clearTimeout(showSetTimeout);
        }
    },
    computed: {},
    methods: {
        getStory() {
            let params = {
                ip: 0,
                wr: 1,
                pid: this.memberKey,
            };
            if (util.isEmpty(this.nowKey)) {
                params.crsd = this.$moment().format(`YYYY-MM-DD`)
            } else {
                params.sid = this.nowKey;
            }

            EventBus.$emit('changeLoading', true);
            try {
                this.$api.$story.getStory(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type !== 1) {
                        this.errorAlert('데이터를 불러올 수 없습니다.');
                        this.$router.back();
                        EventBus.$emit('changeLoading', false);
                        return false;
                    }
                    this.makeArr(res.List)
                    EventBus.$emit('changeLoading', false);
                })
            } catch (e) {
                console.log(e);
                EventBus.$emit('changeLoading', false);
            }
        },

        makeArr(pushArr) {
            this.allStoryData = this.allStoryData.concat(pushArr);

            let makeData = this.$_.chunk(this.allStoryData, 10);
            this.storyData = makeData;
            if (pushArr.length > 0) {
                let lastArr = pushArr[pushArr.length - 1];
                this.nowKey = lastArr.s_idx;
            }
            if (!util.isEmpty(this.storyData[0]) && this.storyData[0].length > 9) {
                this.storyStatus = true;
            }
        },
        returnFile(data) {
            if (!util.isEmpty(data.Files)) {
                return data.Files[0]
            }
            return [];
        },

        checkShowData() {
            if (showSetTimeout) {
                clearTimeout(showSetTimeout);
            }
            if (util.isEmpty(this.$refs.marquee)) {
                showSetTimeout = setTimeout(() => {
                    this.checkShowData();
                }, 500)
                return
            }

            if (this.storyData[this.currentIndex].length > 9 && !util.isEmpty(this.storyData[this.currentIndex + 1]) && this.storyData[this.currentIndex + 1].length > 9 && this.firstShow) {
                this.currentIndex = this.currentIndex + 1;
            }
            this.firstShow = true;


            showSetTimeout = setTimeout(() => {
                this.checkShowData();
            }, 31600)
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
