<template>
  <div>
  </div>
</template>

<script>
export default {
  name: "Policy",
  mixins: [],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {}
  },
  beforeRouterEnter() {
  },
  created() {
    window.location.href= "https://www.hoddak.com/policy"
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {},
  watch: {},
}
</script>

<style scoped>

</style>
