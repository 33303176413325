<template>
    <div class="container-home">
        <div class="wrapper-main">
            <div class="img-logo"></div>
            <div class="home-title-text">
                공연을 선택하세요
            </div>
            <perform-swiper-component></perform-swiper-component>
        </div>
    </div>
</template>

<script>
import PerformSwiperComponent from "@/components/perform/PerformSwiperComponent";

export default {
    name: 'Home',
    mixins: [],
    components: {PerformSwiperComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
