<template>
    <component :is="component" v-if="component" :boardData="boardData" :paginationData="paginationData"></component>
</template>

<script>
export default {
    name: "BoardListLink",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        listLayout: '',
        boardData: {},
        paginationData: {
            type: Object,
            default: {
                total: 0,
                page: 0,
                size: 0,
            }
        },
    },
    data() {
        return {
            component: null
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.listLoader();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        loader() {
            if (!this.listLayout) {
                return null
            }
            return () => import(`@/components/board/layout/boardList/list/${this.listLayout}`)
        },
    },
    methods: {
        listLoader() {
            this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/components/board/layout/boardList/list/BoardListDefaultLayout')
                })
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
