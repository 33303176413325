<template>
    <div>
        <div class="container-home perform-home">
            <board-list-component :boardType="`perform`" :defaultFilter="defaultFilter"></board-list-component>
        </div>
        <page-footer :performKey="defaultFilter.pid"></page-footer>
    </div>
</template>

<script>
import BoardListComponent from "@/components/board/BoardListComponent";
import PageFooter from "@/components/PageFooter";

export default {
    name: "Perform",
    mixins: [],
    components: {PageFooter, BoardListComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            defaultFilter: {
                pid: this.$route.params.idx,
            },
        }
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
