import Swal from 'sweetalert2'

/*
alert 관련 메시지 이벤트 저장 후 사용
 */

export default {
    methods: {
      createAlert(params){
        Swal.fire({
          title: !params.title ? '' : params.title,
          html: !params.content ? '' : params.content,
          confirmButtonText: !params.btnText ? '확인' : params.btnText,
          customClass: 'curious_default',
          onClose: () => {
            if(params.back){
              window.history.back();
            }else if(params.hide) {
              params.hide();
            }
          }
        })
      },
        createConfirm(params){
            Swal.fire({
                title: !params.title ? '' : params.title,
                html: !params.content ? '' : params.content,
                confirmButtonText: !params.confirmText ? '확인' : params.confirmText,
                cancelButtonText: !params.cancelText ?'취소' : params.cancelText,
                showCancelButton: true,
                customClass: !params.customClass ? 'challenge_default' : params.customClass,
                // showCloseButton: true,
                // allowOutsideClick: false,
            }).then((result) => {
                if (result.value) {
                    if(params.confirm)
                        params.confirm();
                } else {
                    if(params.cancel)
                        params.cancel();
                }
            })
        },
        reloadAlert(params){
            Swal.fire({
                title: params.title,
                html: params.content,
                confirmButtonText: params.btnText,
                onClose: () => {
                    location.reload();
                }
            })
        },
        listDeleteAlert(params){
            Swal.fire({
                title: '',
                text: '삭제하시겠습니까?',
                confirmButtonText:  '확인',
                cancelButtonText: '취소',
                showCancelButton: true,
            }).then((result) => {
                if (result.value) {
                    params.element.dataDelete(params.key);
                } else {

                }
            });
        },
        listDeleteInputConfirm(params) {
            Swal.fire({
                title: '게시글 삭제',
                html: '정말 게시물을 삭제 처리 하시겠습니까?<br/>삭제처리 이후엔 복구가 불가능합니다.',
                confirmButtonText:  '확인',
                cancelButtonText: '취소',
                input: 'password',
                customClass: 'papas_pop',
                showCancelButton: true,
                inputAutoTrim: false,
                inputPlaceholder: '관리자 비밀번호',
                preConfirm: (password) => {
                    let loginParam = {
                        m_type: 'admin',
                        mb_id: params.id,
                        mb_password: password
                    }
                    params.element.$api.$auth.login(loginParam).then(res => res.Data.Result).then(res => {
                        if(res.Info.type === 1) {
                            params.element.dataDelete(params.key,password);
                        }else{
                            params.element.errorAlert('비밀번호가 다릅니다.');
                        }
                    })
                }
            })
        },
        openInputAlert(params) {
            Swal.fire({
                title: !params.title ? '' : params.title,
                confirmButtonText: !params.btnText ? '확인' : params.btnText,
                inputAutoTrim: false,
                inputPlaceholder: !params.placeholder ? '' : params.placeholder,
                input: !params.inputType ? 'text' : params.inputType,
                inputValidator: !params.validator ? () => {} : params.validator,
                inputAttributes: !params.attributes ? {} : params.attributes,
            }).then((result) => {
                if(params.hide) {
                    params.hide(result.value);
                }
            })
        },
        openInputConfirm(params) {
            Swal.fire({
                title: !params.title ? '' : params.title,
                confirmButtonText: !params.confirmText ? '확인' : params.confirmText,
                cancelButtonText: !params.cancelText ? '취소' : params.cancelText,
                inputAutoTrim: false,
                showCancelButton: true,
                inputPlaceholder: !params.placeholder ? '' : params.placeholder,
                input: !params.inputType ? 'text' : params.inputType,
                inputValidator: !params.validator ? () => {} : params.validator,
                inputAttributes: !params.attributes ? {} : params.attributes,
            }).then((result) => {
                if (result.value) {
                    if(params.confirm)
                        params.confirm(result.value);
                } else if(result.dismiss === 'cancel') {
                    if(params.cancel)
                        params.cancel();
                }
            })
        },
        DeleteListAlert(params) {
            Swal.fire({
                title: '',
                text: '삭제하시겠습니까?',
                confirmButtonText: '확인',
                cancelButtonText: '취소',
                showCancelButton: true,
                // showCloseButton: true
            }).then((result) => {
                if (result.value) {
                    params.element.arDelete(params);
                } else {

                }
            })
        },

        deleteAlert(params){
            Swal.fire({
                title: '',
                html: '삭제 완료되었습니다.',
                confirmButtonText: '확인',
                onClose: () => {
                    params.element.getList();
                }
            })
        },

        validateAlert(params){
            Swal.fire({
                title: '',
                html: '필수 데이터를 확인해주세요.',
                confirmButtonText: '확인',
                onClose: () => {
                }
            })
        },

        errorAlert(params){
            Swal.fire({
                title: '',
                html: params,
                confirmButtonText: '확인',
                customClass: !params.customClass ? 'challenge_default' : params.customClass,
                onClose: () => {

                }
            })
        },

        backErrorAlert(params){
            Swal.fire({
                title: '',
                html: params,
                confirmButtonText: '확인',
                onClose: () => {
                    window.history.back();
                }
            })
        },

        modalSaveAlert(params){

            Swal.fire({
                title: '',
                html: '저장완료.',
                confirmButtonText: '확인',
                onClose: () => {
                    params.element.modalHide();
                }
            })
        },

        historyBackAlert(params){
            Swal.fire({
                title: '',
                html: params.content,
                confirmButtonText: params.btnText,
                onClose: () => {
                    window.history.back();
                }
            })
        },
    }
}
