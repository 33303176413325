<template>
  <div>
    <div class="container-home">
      <vote-component :voteKey="voteKey"></vote-component>
    </div>
    <page-footer :performKey="voteKey"></page-footer>
  </div>
</template>

<script>
import PageFooter from "@/components/PageFooter";
import VoteComponent from "@/components/vote/VoteComponent";

export default {
  name: "Vote",
  mixins: [],
  components: {VoteComponent, PageFooter},
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
      voteKey: this.$route.params.idx,
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {},
  watch: {},
}
</script>

<style scoped>

</style>
