<template>
    <component :is="component" v-if="component"></component>
</template>

<script>
export default {
    name: "BoardEmptyLink",
    mixins: [],
    components: {},
    inject: [],
    provide(){
        return{}
    },
    props:{
        emptyLayout: '',
    },
    data(){
        return{
            component: null
        }
    },
    beforeRouterEnter(){},
    created(){},
    mounted() {
        this.emptyLoader();
    },
    beforeDestroy() {},
    destroyed(){},
    computed:{
        loader() {
            if (!this.emptyLayout) {
                return null
            }
            return () => import(`@/components/board/layout/boardList/empty/${this.emptyLayout}`)
        },
    },
    methods:{
        emptyLoader() {
            this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/components/board/layout/boardList/empty/BoardEmptyDefaultLayout')
                })
        }
    },
    watch:{},
};
</script>

<style scoped>

</style>