import Api from "./api";

let Content = class {
    constructor(options) {
        this.url = options.url;
        this.defaultParams = options.defaultParams;
    }

    getList(filterSet) {
        let params = this.makeParams(filterSet)
        /*if(params.hasOwnProperty('nid') && params.hasOwnProperty('nid')) {
            delete params.pid;
        }*/
        try {
            return this.url(params).then(res => res.Data.Result).then(res => {
                let list = [];
                let progress = [];
                let total = 0;
                if(res.List.hasOwnProperty('Content')) {
                    list = res.List.Content;
                }
                if(res.List.hasOwnProperty('Progress')) {
                    progress = res.List.Progress;
                }
                if(res.hasOwnProperty('Page')) {
                    total = res.Page.total;
                }
                return {
                    list: list,
                    total: total,
                    progress: progress,
                }
            })
        }catch (e) {
            console.log(e);
        }
    }

    makeParams(filterSet) {
        let returnParams = JSON.parse(JSON.stringify(this.defaultParams));
        let filterList = Object.values(filterSet.filter);
        filterList.forEach(obj => {
            if(obj.hasOwnProperty('query')) {
                returnParams[obj.key] = obj.query;
            }else if(obj.hasOwnProperty('value')) {
                returnParams[obj.key] = obj.value;
            }else if(obj.hasOwnProperty('start') && obj.hasOwnProperty('end')) {
                returnParams[obj.key+'sd'] = obj.start;
                returnParams[obj.key+'ed'] = obj.end;
            }
        });
        if(filterSet.sort.hasOwnProperty('key') && filterSet.sort.hasOwnProperty('order')) {
            returnParams.orderByType = filterSet.sort.key;
            returnParams.orderBySort = filterSet.sort.order;
        }
        if(filterSet.page.hasOwnProperty('page')) {
            returnParams.page = filterSet.page.page;
        }
        if(filterSet.page.hasOwnProperty('size')) {
            returnParams.sp = filterSet.page.size;
        }
        return returnParams;
    }
}

export default Content;
