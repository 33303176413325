<template>
    <div class="candidate_num">
        {{ candidateData.length }}표
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";

export default {
    name: "VoteUserComponent",
    mixins: [alertMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        voteData: {},
        userKey: 0,
    },
    data() {
        return {
            candidateData: [],
            timeOutId: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.getVoteCnt();
    },
    beforeDestroy() {
        clearTimeout(this.timeOutId);
    },
    destroyed() {
    },
    computed: {},
    methods: {
        getVoteCnt() {
            let params = {};

            params.vc_idx = this.userKey;
            EventBus.$emit('changeLoading', true);
            try {
                this.$api.$vote.getUser(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type !== 1) {
                        this.errorAlert('데이터를 불러올 수 없습니다.');
                        this.$router.back();
                        EventBus.$emit('changeLoading', false);
                        return false;
                    }
                    this.candidateData = res.List;
                    EventBus.$emit('changeLoading', false);

                    this.timeOutId = setTimeout(() => {
                        this.getVoteCnt();
                    }, 2000);
                })
            } catch (e) {
                console.log(e);
                EventBus.$emit('changeLoading', false);
            }
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
