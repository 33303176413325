<template>
    <div>
        <div class="container-home">
            <board-detail-component :boardType="`performDetail`" :boardKey="boardKey"></board-detail-component>
        </div>
        <page-footer :hasBack="true" :performKey="performKey"></page-footer>
    </div>
</template>

<script>

import BoardDetailComponent from "@/components/board/BoardDetailComponent";
import PageFooter from "@/components/PageFooter";

export default {
    name: "PerformDetail",
    mixins: [],
    components: {PageFooter, BoardDetailComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            performKey: this.$route.params.idx,
            boardKey: this.$route.params.wr_id,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
